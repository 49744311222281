import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useCalculateBonus } from "./useCalculateBonus";

const { getBonusData } = require("../fetches/fetch");

export function Bonus({ currentUserData, userIdAndFullName }) {
  const [bonusData, setBonusData] = useState();
  const calculatedBonusData = useCalculateBonus(bonusData);
  const [fromDate, setFromDate] = useState(() => {
    const startOfMonth = new Date();
    startOfMonth.setMonth(startOfMonth.getMonth() - 1);
    startOfMonth.setDate(1);
    startOfMonth.setHours(0, 0, 0, 0);
    return startOfMonth.toISOString().split("T")[0];
  });
  const [toDate, setToDate] = useState(() => {
    const endOfMonth = new Date();
    endOfMonth.setDate(0);
    return endOfMonth.toISOString().split("T")[0];
  });



  const handleSettingBonusData = async () => {
    let response;
    if (currentUserData[0].role === "Agent") {
      response = await getBonusData(Cookies.get("userid"), fromDate, toDate);
    } else {
      response = await getBonusData(null, fromDate, toDate);
    }
    if (response.status === 500) {
      return alert("Error getting bonus data");
    } else {
      const data = await response.json();
      setBonusData(data);
    }
  };

  useEffect(() => {
    handleSettingBonusData();
  }, [toDate, fromDate]);

  useEffect(() => {
    handleSettingBonusData();
  }, []);
  return (
    <>
      <div>
        <div>
          From:
          <input
            type="date"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
          />
          To:
          <input
            type="date"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
          />
        </div>
        <table>
          <thead>
            <tr>
              <th>Producer Name</th>
              <th>Number Of Items</th>
              <th>Number Of Policies</th>
              <th>Monoline</th>
              <th>Multiline</th>
              <th>Premium</th>
              <th>Bundled Rate</th>
              <th>Bonus Amount</th>
              <th>Bonus After Bundle Percentage Calculation</th>
            </tr>
          </thead>
          <tbody id="search-results_table_body">
            {calculatedBonusData &&
              calculatedBonusData.map((userBonusData) =>
                userBonusData.receive_bonus === true ? (
                  <>
                    <tr className="">
                      <td>
                        {userIdAndFullName[userBonusData.user_created_id]}
                      </td>
                      <td>{userBonusData.numOfItems}</td>
                      <td>{userBonusData.numOfPolicies}</td>
                      <td>{userBonusData.numOfMonoline}</td>
                      <td>{userBonusData.numOfMultiline}</td>
                      <td>{"$" + userBonusData.total_prem.toFixed(2)}</td>
                      <td>{userBonusData.bundledRate}%</td>
                      <td>{"$" + userBonusData.bonusAmount}</td>
                      <td>{"$" + userBonusData.finalBonusAmount}</td>
                    </tr>
                  </>
                ) : null
              )}
          </tbody>
        </table>
      </div>
    </>
  );
}
