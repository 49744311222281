import React, { useRef, useEffect, useState } from "react";
import Chat_Input from "./Chat_Input";
import ChatMessage from './ChatMessage'
import Cookies from "js-cookie";
function Chat_Section({ handleGettingMessages, selectedUser, currentMessages, userIdAndFullName }) {
  const chatContainerRef = useRef(null); // Reference to the chat container
  const [isScrolledDown, setIsScrolledDown] = useState();
  useEffect(() => {
    if (chatContainerRef.current) {
      if (currentMessages) {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
      }
      setIsScrolledDown(true);
    }
  }, [currentMessages]); // Dependency array to trigger scroll on new messages

  return (
    <div className="max-width text-align-center flex-column-no-ho-centered max-height ">
      <span className="padding-bottom-5px">{userIdAndFullName[selectedUser]}</span>
      <div
        ref={chatContainerRef}
        className="border-bottom height-80-percent padding-10px overflow-chatContainer"
      >
        {isScrolledDown === true && currentMessages ? (
          currentMessages.map((message) => (
            <ChatMessage
              message={message.text}
              isReceiving={
                Number(message.from_user_id) === Number(Cookies.get("userid"))
              }
              sender={userIdAndFullName[message.from_user_id]}
              timeStamp={message.date_created}
            />
          ))
        ) : (
          <></>
        )}
      </div>
      <div className="flex-one">
        <Chat_Input handleGettingMessages = {handleGettingMessages}  selectedUser = {selectedUser}  />
      </div>
    </div>
  );
}

export default Chat_Section;
