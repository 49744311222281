import { React, useState, useEffect } from "react";
import Cookies from "js-cookie";
import EditUser from "./user_management_popups/editUser.js";
import AddUserPopup from "./user_management_popups/AddUserPopup.js";
import DeleteUser from "./user_management_popups/DeleteUser.js";
import QRCode from "qrcode";
let apiConfig = require("./apiConfig.js");
let api = apiConfig.api;
const jwt = Cookies.get("jwt");
const { generateNewSecret } = require("../src/fetches/fetch.js");



function UserManagement() {
  const [userData, setUserData] = useState();
  const [editUserPopup, setEditUserPopup] = useState(false);
  const [userToEditData, setUserToEditData] = useState();
  const [addUserPopup, setAddUserPopup] = useState(false);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState();
  const [toggleWarning, setToggleWarning] = useState();
  const [generateSecretForUserID, setGenerateSecretForUserID ] = useState();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const response = await fetch(`${api}/api/users/all`, {
      headers: {
        Authorization: jwt,
      },
    });

    const data = await response.json();

    setUserData(data);
  };

  const togglePopUpById = (id) => {
    document.getElementById(id).hidden = !document.getElementById(id).hidden;
  };

  const fetchDataNewUser = async (userDataObj) => {
    const response = await fetch(`${api}/api/users/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: jwt,
      },
      body: JSON.stringify(userDataObj),
    });
    if (response.ok) {
      fetchUsers();
    } else {
      alert("error could not create user!");
    }
  };



  const deleteUser = (user_id) => {
    fetch(`${api}/api/user?user_id=${user_id}`, { method: "DELETE" })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Failed to delete user. Status Code: ${response.status}`
          );
        }
        fetchUsers();
        setToggleDelete(false);
        return response.json();
      })
      .catch((error) => console.error(error.message));
  };

  return (
    <>
      {toggleDelete && (
        <DeleteUser
          setToggleDelete={setToggleDelete}
          deleteUser={deleteUser}
          selectedUserId={selectedUserId}
        />
      )}
      {editUserPopup ? (
        <EditUser
          setEditUserPopup={setEditUserPopup}
          userToEditData={userToEditData}
          api={api}
          fetchUsers={fetchUsers}
        />
      ) : null}
      {addUserPopup ? (
        <AddUserPopup
          fetchDataNewUser={fetchDataNewUser}
          togglePopUpById={togglePopUpById}
          setAddUserPopup={setAddUserPopup}
        />
      ) : null}
      {userData ? (
        <div className="search-overflow-wrapper">
          <table className="max-width " id="search-results">
            <thead>
              <tr>
                <th
                  className="hover-cursor-pointer"
                  onClick={() => setAddUserPopup(true)}
                >
                  + User ID
                </th>
                <th>Username</th>
                <th>Full Name</th>
                <th>Role</th>
                <th>Email</th>
                <th>Two Factor Authentication</th>
              </tr>
            </thead>
            <tbody id="search-results_table_body" className="">
              {userData
                ? userData.map((user) => (
                    <tr>
                      <td>
                        <div
                          className="inline-el"
                          onClick={() => {
                            setSelectedUserId(user.id);
                            setToggleDelete(true);
                          }}
                        >
                          🗑️
                        </div>
                        <div
                          className="inline-el"
                          onClick={() => {
                            setEditUserPopup(true);
                            setUserToEditData(user);
                          }}
                        >
                          🖊️
                        </div>
                        {user.id}
                      </td>

                      <td>{user.username}</td>
                      <td>{user.full_name}</td>

                      <td>{user.role}</td>

                      <td>{user.email}</td>
                      <td>
                        <button className="green-submit-btn" onClick ={() => { 
                          setToggleWarning(true);
                          setGenerateSecretForUserID(user.id)
                        }}>
                          Generate Secret
                        </button>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="flex-center-column light-dark-container">
          <img
            className="light-dark-container ten-percent-width"
            src="https://i.gifer.com/ZKZg.gif"
          />
        </div>
      )}
      {toggleWarning && < GenerateSecretWarning setToggleWarning={setToggleWarning} generateSecretForUserID={generateSecretForUserID}  />}
    </>
  );
}

export default UserManagement;




function GenerateSecretWarning({setToggleWarning, generateSecretForUserID}) {

  const handleGenerateNewSecret = async(user_id) => {
    try{  
      const response = await generateNewSecret(user_id);
      const data = await response.json();
      return data;
    }catch(error){ 
      alert(error);
    }
   
  };
  const generateQRCode = async(url) =>{ 
    try{ 
    const qrCodeData = await QRCode.toDataURL(url, {width: 300})
    return qrCodeData;
    } catch(error){ 
      console.error(error);
    }
  }

  const downloadQRCode = (qrCodeData) => { 
    const link = document.createElement("a"); 
      link.href = qrCodeData;
      link.download = `user_id_${generateSecretForUserID}_QR_SECRET@${new Date().toLocaleDateString()}.png`;
      link.click();
    
  }
  const handleGeneratingQRCode = async()=> { 
    try{ 
      const otpauth_url = await handleGenerateNewSecret(generateSecretForUserID);
      const qrCodeData = await generateQRCode(otpauth_url); 
      downloadQRCode(qrCodeData);
      setToggleWarning(null)
    }catch(error){ 
      console.error(error);

    }
  } 

  
  return (
    <>
      <div
        className="background-color-full-dark border-radius-10px popup"
        id="add-notes"
      >
        <div className="black-background  text-align-right border-radius-top-10px ">
          <button
            type="button"
            className="popup-exit-button"
            onClick={() => setToggleWarning(null)}
          >
            ❌
          </button>
        </div>
        <div className="flex-container-el padding-20px">
          <h3 className="text-align-left ">Warning this action cannot be reversed!</h3>
          
          <button
            onClick = { () => { 
              handleGeneratingQRCode();
            }}
            type="button"
            class="button-default green-submit-btn margin-top-10px max-width "
            id="add=driver-submit-btn"
        
          >
            I understand, proceeed.
          </button>
        </div>
      </div>
    </>
  );
}
