import { useEffect, useState } from "react";
const { getUserSalaries } = require("../fetches/fetch.js");

export const useGetUserSalary = (startDate, endDate) => {
  const [userSalaries, setUserSalaries] = useState(null);
  const handleGetUserSalaries = async (startDate, endDate) => {
    const response = await getUserSalaries(startDate, endDate);
    const data = await response.json();
    setUserSalaries(data);
  };

  useEffect(() => {
    if (startDate && endDate) {
      handleGetUserSalaries(startDate, endDate);
    }
  }, [startDate, endDate]);

  return userSalaries;
};
