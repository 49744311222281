import React from "react";

function ChatMessage({ message, sender, isReceiving, timeStamp }) {
  return (
    <>
      { isReceiving === false ? (
        <div className="flex-column-no-ho-centered">
          <div className="text-align-left  padding-10px ">
            <div className="chat-bubble background-color-receive-chat">{message}</div>
          </div>
          <span className="text-align-left  padding-left-10px font-small ">
            <i>{sender + " "}</i>
            @ 
            { " " + new Date(timeStamp).toLocaleTimeString([], {
              weekday: "short",
              hour: "2-digit",
              minute: "2-digit",
            })}{" "}
          </span>
        </div>
      ) : isReceiving === true  && (
        <div className="flex-column-no-ho-centered">
          <div className="text-align-right  padding-10px">
          <div className={`background-green  chat-bubble ${message.length >50 ? "long-text" : ""}`}>{message}</div>
          </div>
          <span className="text-align-right  padding-left-10px font-small">
            <i>You @ </i>
            {" " }
            { new Date(timeStamp).toLocaleTimeString([], {
              weekday: "short",
              hour: "2-digit",
              minute: "2-digit",
            })}{" "}
          </span>
        </div>
      )}
    </>
  );
}

export default ChatMessage;
