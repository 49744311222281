import React, { useState } from "react";
import Payroll from "./Payroll";
import { ManagementNavigation } from "./ManagementNavigation";
import { TimesheetPage } from "./TimeSheetPage";
import {Commissions} from './CommissionsPage'
import {Bonus } from './BonusPage'

const {
  getBonusData,
} = require("../fetches/fetch");


export default function ManagementContainer({
  userIdAndFullName,
  currentUserData,
  allUserData,
  fetchUserData,
  setCurrentHouseholdId,
}) {
  const [currentManagementPage, setCurrentManagementPage] = useState(0);
  const pages = [
    <TimesheetPage
      userIdAndFullName={userIdAndFullName}
      currentUserData={currentUserData}
      allUserData={allUserData}
      fetchUserData={fetchUserData}
    />,
    <Payroll
      userIdAndFullName={userIdAndFullName}
      allUserData={allUserData}
      getBonusData={getBonusData}
    />,
    <Commissions
      setCurrentManagementPage={setCurrentManagementPage}
      userIdAndFullName={userIdAndFullName}
      currentUserData={currentUserData}
      setCurrentHouseholdId={setCurrentHouseholdId}
      allUserData={allUserData}
    />,
    <Bonus
      currentUserData={currentUserData}
      userIdAndFullName={userIdAndFullName}
    />,
  ];

  
  return (
    <div className="max-height">
      <ManagementNavigation
        setCurrentManagementPage={setCurrentManagementPage}
        currentManagementPage={currentManagementPage}
        currentUserData={currentUserData}
      />
      {pages[currentManagementPage]}
    </div>
  );
}
