import React, { useState, useEffect } from "react";

import CommissionPolicyPopup from "../policy_popups/CommissionPolicyPopup";




const {
  getUserCommissions,
} = require("../fetches/fetch");



export function Commissions({
  currentUserData,
  userIdAndFullName,
  setCurrentHouseholdId,
  allUserData,
}) {
  const [toggleCommissionPopup, setToggleCommissionPopup] = useState(false);
  const [currentUserID, setCurrentUserID] = useState();
  const [userCommissions, setUserCommmissions] = useState();

  const [fromDate, setFromDate] = useState(() => {
    const startOfMonth = new Date();
    startOfMonth.setMonth(startOfMonth.getMonth() - 1);
    startOfMonth.setDate(1); // Set to the first day of the month
    startOfMonth.setHours(0, 0, 0, 0);
    return startOfMonth.toISOString().split("T")[0];
  });
  const [toDate, setToDate] = useState(() => {
    const endOfMonth = new Date();
    endOfMonth.setDate(0); // Set to the last day of the previous month
    return endOfMonth.toISOString().split("T")[0];
  });

  const setUserCommissionsData = async (fromDate, toDate) => {
    const response = await getUserCommissions(fromDate, toDate);
    const data = await response.json();
    setUserCommmissions(data);
  };

  useEffect(() => {
    setUserCommissionsData(fromDate, toDate);
  }, [toDate, fromDate]);

  

  return (
    <>
      {toggleCommissionPopup && (
        <CommissionPolicyPopup
          setToggleCommissionPopup={setToggleCommissionPopup}
          userIdAndFullName={userIdAndFullName}
          setCurrentUserID={setCurrentUserID}
          currentUserID={currentUserID}
          setCurrentHouseholdId={setCurrentHouseholdId}
        />
      )}
      <div>
        <span>From: {" " + " "}</span>
        <input
          type="date"
          value={fromDate}
          data-testid="from-date-input"
          onChange={(e) => setFromDate(e.target.value)}
        />
        <span>To:{" " + " "}</span>
        <input
          type="date"
          value={toDate}
          data-testid="to-date-input"
          onChange={(e) => setToDate(e.target.value)}
        />
      </div>
      <table>
        <thead>
          <tr>
            <th>Full Name</th>
            <th>Total Premium Amount</th>
            <th>MVR Fee</th>
            <th>Clue Fee</th>
            <th>Commmission Amount</th>
            <th>Final Amount</th>
          </tr>
        </thead>
        <tbody id="search-results_table_body">
          {userCommissions &&
            userCommissions.map((user, index) =>
              currentUserData[0].role === "Admin" &&
              allUserData.find((userData) => userData.id === user.id)
                .receive_commission === true ? (
                <tr
                  data-testid={`username-${index}`}
                  onClick={() => {
                    setCurrentUserID(user.id);
                    setToggleCommissionPopup(true);
                  }}
                >
                  <td>{user.full_name}</td>
                  <td>${user.total_prem.toFixed(2)}</td>
                  <td data-testid={`MVR-Fee-${index}`}>
                    ${user.total_mvr_fee}
                  </td>
                  <td data-testid={`Clue-Fee-${index}`}>
                    ${user.total_clue_fee}
                  </td>
                  <td data-testid={`Commission-Amount-${index}`}>
                    ${user.commission_amount.toFixed(2)}
                  </td>
                  <td data-testid={`Final-Amount-${index}`}>
                    $
                    {(
                      user.commission_amount -
                      user.total_mvr_fee -
                      user.total_clue_fee
                    ).toFixed(2)}
                  </td>
                </tr>
              ) : (
                user.id === currentUserData[0].id &&
                currentUserData[0].role === "Agent" && (
                  <tr>
                    <td>{user.full_name}</td>
                    <td>${user.total_prem.toFixed(2)}</td>
                    <td>${user.total_mvr_fee}</td>
                    <td>${user.total_clue_fee}</td>
                    <td>${(user.commission_amount).toFixed(2)}</td>
                    <td>
                      $
                      {(
                        user.commission_amount  -
                        user.total_mvr_fee -
                        user.total_clue_fee
                      ).toFixed(2)}
                    </td>
                  </tr>
                )
              )
            )}
        </tbody>
      </table>
    </>
  );
}