import React, { useEffect, useState } from "react";
import { useCalculateBonus } from "./useCalculateBonus";
const { getUserCommissions, getBonusData } = require("../fetches/fetch");

function PayrollCommissionAndBonusTable({
  userIdAndFullName,
  startDate,
  endDate,
  allUserData,
}) {
  const [commissionsData, setCommissionsData] = useState();
  const [bonusData, setBonusData] = useState();
  const calculatedBonusData = useCalculateBonus(bonusData);
  const periodStart = new Date();
  periodStart.setFullYear(startDate.getFullYear());
  periodStart.setMonth(startDate.getMonth() - 1);
  periodStart.setDate(1);
  const periodEnd = new Date();
  periodEnd.setFullYear(startDate.getFullYear());
  periodEnd.setMonth(startDate.getMonth(), 0);

  useEffect(() => {
    fetchBonusData(periodStart, periodEnd);
    setUserCommissionsData(periodStart, periodEnd);
  }, [startDate, endDate]);

  const setUserCommissionsData = async (startDate, endDate) => {
    const response = await getUserCommissions(startDate, endDate);
    const data = await response.json();
    setCommissionsData(data);
  };

  const fetchBonusData = async (startDate, endDate) => {
    const response = await getBonusData(
      null,
      startDate.toISOString().split("T")[0],
      endDate.toISOString().split("T")[0]
    );
    if (response.status === 500) {
      return alert("Error fetching bonus data");
    }
    const data = await response.json();

    setBonusData(data);
  };

  return (
    <>
      <div className="flex-across-space-between">
        <div className="flex-across-space-between align-items-center ">
          <h3>
            Commission and Bonus
            <i>
              {" "}
              {periodStart &&
                periodStart.toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "long",
                }) +
                  " - " +
                  periodEnd &&
                periodEnd.toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "long",
                })}
            </i>
          </h3>
          <h3 className="margin-left-10">
            {+" " + periodStart && periodStart.getFullYear()}
          </h3>
        </div>
        <h3 className="margin-right-20px">
          Processed Date 24th Of{" "}
          {startDate.toLocaleDateString("en-US", {
            month: "long",
            year: "numeric",
          })}{" "}
        </h3>
      </div>

      <table>
        <thead>
          <th>Employee</th>
          <th>Bonus </th>
          <th>Commissions</th>
          <th>Total Pay</th>
        </thead>
        <tbody>
          {allUserData &&
            allUserData.map((userPayrollData) => {
              return userPayrollData.on_payroll === true &&
                calculatedBonusData &&
                commissionsData ? (
                <tr className="border-bottom">
                  <td>{userIdAndFullName[userPayrollData.id]}</td>

                  <td>
                    $
                    {calculatedBonusData &&
                    calculatedBonusData.find(
                      (user) =>
                        user.user_created_id == userPayrollData.id &&
                        userPayrollData.receive_bonus === true
                    )
                      ? calculatedBonusData.find(
                          (user) => user.user_created_id == userPayrollData.id
                        )?.finalBonusAmount
                      : 0}
                  </td>
                  <td>
                    $
                    {(() => {
                      const user = commissionsData?.find(
                        (user) =>
                          user.id == userPayrollData.id &&
                          userPayrollData.receive_commission === true
                      );

                      const commissionAmount = user?.commission_amount;
                      const total_mvr_fee = user?.total_mvr_fee;
                      const total_clue_fee = user?.total_clue_fee;

                      return commissionAmount && !isNaN(commissionAmount)
                        ? (
                            commissionAmount -
                            total_mvr_fee -
                            total_clue_fee
                          ).toFixed(2)
                        : 0;
                    })()}
                  </td>
                  <td>
                    $
                    {(() => {
                      const commissionData = commissionsData?.find(
                        (user) =>
                          user.id == userPayrollData.id &&
                          userPayrollData.receive_commission === true
                      );
                      const bonusData = calculatedBonusData.find(
                        (user) =>
                          user.user_created_id == userPayrollData.id &&
                          userPayrollData.receive_bonus === true
                      );
                      const commissionAmount = commissionData?.commission_amount
                        ? commissionData?.commission_amount
                        : 0;
                      const bonusAmount = bonusData?.finalBonusAmoun
                        ? bonusData?.finalBonusAmount
                        : 0;
                      const total_mvr_fee = commissionData?.total_mvr_fee;
                      const total_clue_fee = commissionData?.total_clue_fee;

                      return commissionAmount && !isNaN(commissionAmount)
                        ? (
                            bonusAmount +
                            (commissionAmount - total_mvr_fee - total_clue_fee)
                          ).toFixed(2)
                        : 0;
                    })()}
                  </td>
                </tr>
              ) : null;
            })}
        </tbody>
      </table>
    </>
  );
}

export default PayrollCommissionAndBonusTable;
