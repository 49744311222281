import React, { useState, useEffect } from "react";
import DateRangePicker from "../DatePicker";
import Cookies from "js-cookie";
import { TimeTable } from "./TimeTable";
import { useCalculatePay } from "./useCaculatePay";
const {
  get_time_punch
} = require("../fetches/fetch");

export function TimesheetPage({
  currentUserData,
  userIdAndFullName,
  allUserData,
  fetchUserData,
}) {
 
  const [timePunches, setTimePunches] = useState();
  const [user, setUser] = useState(Cookies.get("userid"));
  const { totalHours, totalPay } =
  useCalculatePay(timePunches);
  const [startDate, setStartDate] = useState(
    getFirstDateOfWeek().toISOString()
  );
  const [endDate, setEndDate] = useState(new Date().toISOString());

  useEffect(() => {
    returnTimePunches();
  }, [startDate, endDate, user]);




//Default to first day of the week upon load.
  function getFirstDateOfWeek() {
    const now = new Date(); 
    const dayOfWeek = now.getDay(); 
    const diff = now.getDate() - dayOfWeek;
    return new Date(now.setDate(diff)); 
  }

  const returnTimePunches = async () => {
    await fetchUserData(); // Get all user data 
    const response = await get_time_punch(startDate, endDate, user);
    const time_punches = await response.json();
    setTimePunches(time_punches);
  };


  return (
    <div>
      <div className=" flex-across align-items-center space-between margin-bottom-10">
        <h3 className="margin-left-10 ">
          {currentUserData && currentUserData[0].role === "Admin" ? (
            <select
              onChange={(e) => {
                setUser(e.target.value);
              }}
            >
              {Object.keys(userIdAndFullName).map((key) => (
                <option value={key}>{userIdAndFullName[key]}</option>
              ))}
            </select>
          ) : (
            <>Agents</>
          )}
          's Timesheet
        </h3>

        <DateRangePicker
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          getFirstDateOfWeek={getFirstDateOfWeek}
          startDate={startDate}
          endDate={endDate}
        />
      </div>
      <div className="grid-management margin-bottom-10">
        <div className="flex-column-no-ho-centered light-dark-background">
          <span className="light-dark-background padding-10px">
            Total Hours
            <h2 className="light-dark-background ">
              {totalHours ? totalHours && totalHours.toFixed(2) : "N/A"}
            </h2>
          </span>
        </div>
        <div className="flex-column-no-ho-centered light-dark-background">
          <span className="light-dark-background padding-10px">
            Estimated Pay
            <h2 className="light-dark-background ">
              ${totalPay ? totalPay.toFixed(2) : "N/A"}
            </h2>
          </span>
        </div>
        <div className="flex-column-no-ho-centered light-dark-background">
          <span className="light-dark-background padding-10px">
            Hourly Pay
            <h2 className="light-dark-background ">
              $
              {allUserData
                ? allUserData.filter((obj) => obj.id === user)[0].hourly_rate
                : "N/A"}
            </h2>
          </span>
        </div>
      </div>
      <TimeTable
        timePunches={timePunches}
        currentUserData={currentUserData}
        user={user}
        returnTimePunches={returnTimePunches}
      />
    </div>
  );
}
