import { useState, useEffect } from "react";
import { get_time_punch } from "../fetches/fetch";

const timeLoggedInMinutes = (arr) => {
  if (arr) {
    return arr.reduce(
      (acc, current) => acc + (current.logged_in_time_minutes || 0),
      0
    );
  }
};

export const useCalculatePayPeriodTotal = (users, startTime, endTime) => {
  const [usersTotalMinutes, setUsersTotalMinutes] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      let totalMinutes = {};

      await Promise.all(
        users.map(async (user) => {
          const response = await get_time_punch(startTime, endTime, user.id);
          const data = await response.json();
          totalMinutes[user.id] = timeLoggedInMinutes(data);
        })
      );

      setUsersTotalMinutes(totalMinutes);
      setLoading(false);
    };

    fetchData();
  }, [users, startTime, endTime]);

  return { usersTotalMinutes, loading };
};
