import React, { useState, useEffect } from "react";
import ConfirmDelete from "../middle_popups/ConfirmDelete.js";
const { deleteTimePunch } = require("../fetches/fetch.js");

export default function TimeTablePopup({
  timePunches,
  setTogglePopup,
  daysOfWeek,
  currentUserData,
  handleTimeTableRowClick,
  user,
  returnTimePunches,
  setToggleEditTimePopup,
  setToggleTimePunch,
  totalHoursWorkedForSelectedDate
}) {
  const [toggleDelete, setToggleDelete] = useState(false);
  const [deletePunchId, setDeletePunchId] = useState();
  const [deleteTimePunchDate, setDeleteTimePunchDate] = useState();
  const [hoursLoggedIn, setHoursLoggedIn] = useState();
  const [hoursLoggedOut, setHoursLoggedOut] = useState();

  const handleDeleteTimePunch = async () => {
    const response = await deleteTimePunch(deletePunchId);
    if (response.status === 500) {
      return alert("Error deleting time punch");
    }
    await handleTimeTableRowClick(deleteTimePunchDate, user);
    await returnTimePunches();
  };

  useEffect(() => {
    setHoursLoggedIn(calculateTimeLoggedIn(timePunches));



  }, [timePunches]);


  
  useEffect(() => {
    setHoursLoggedOut(calculateTimeLoggedOut())
  }, [hoursLoggedIn]);




  const calculateTimeLoggedOut = ( ) => { 
    const loggedInMinutes = hoursLoggedIn
    ? hoursLoggedIn.hours * 60 + hoursLoggedIn.minutes
    : 0;

  // Convert totalHoursWorkedForSelectedDate to total minutes
  const totalMinutesWorked = totalHoursWorkedForSelectedDate
    ? totalHoursWorkedForSelectedDate.hours * 60 + totalHoursWorkedForSelectedDate.minutes
    : 0;

  // Subtract the minutes to find the actual time logged out
  const loggedOutMinutes = totalMinutesWorked - loggedInMinutes;

  // Convert the result back to hours and minutes
  const hours = Math.floor(loggedOutMinutes / 60); // Get the whole number for hours
  const minutes = loggedOutMinutes % 60; // Get the remaining minutes

  return { hours, minutes };
  }


  const calculateTimeLoggedIn = (punches) => {
    punches.sort((a, b) => new Date(a.time_punch) - new Date(b.time_punch));

    let totalLoggedOutTime = 0;

    for (let i = 1; i < punches.length; i++) {
      if (punches[i].action === "logout" && punches[i - 1].action === "login") {
        // Calculate the time difference between logout and login
        const loginTime = new Date(punches[i - 1].time_punch);
        const logoutTime = new Date(punches[i].time_punch);
        const differenceInMilliseconds = logoutTime - loginTime;

        // Add the time difference in minutes
        totalLoggedOutTime += differenceInMilliseconds / 60000; // converting milliseconds to minutes
      }
    }

    // Return total logged out time in hours and minutes
    const hours = Math.floor(totalLoggedOutTime / 60);
    const minutes = totalLoggedOutTime % 60;

    return { hours, minutes };
  };
  console.log(timePunches)

  return (
    <>
      {toggleDelete && (
        <ConfirmDelete
          action={handleDeleteTimePunch}
          setToggleDelete={setToggleDelete}
        />
      )}

      <div
        className="background-color-full-dark border-radius-10px z-index  word-wrap "
        id="household-driver-crud-ops"
      >
       
        <div className="black-background  text-align-right border-radius-top-10px z-index max-wdith flex-across-space-between align-items-center">
          <span className="black-background">
            {timePunches &&
              timePunches.length > 0 &&
              daysOfWeek[new Date(timePunches[0]?.time_punch).getDay()] +
                ", " +
                new Date(timePunches[0]?.time_punch).toLocaleDateString()}
          </span>
          <button
            type="button"
            className="popup-exit-button"
            onClick={() => setTogglePopup(false)}
          >
            ❌
          </button>
        </div>
        <div className="flex-container-el padding-10px ">
          <div className=" max-width overflow-wrapper padding-10px  ">
            {timePunches &&
              timePunches.map((timePunch) => (
                <div className=" timeSheetPopupContainer margin-bottom-10 align-items-center">
                  <p> {timePunch.action.toUpperCase() + " "}</p>

                  <li className="">
                    {daysOfWeek[new Date(timePunch.time_punch).getDay()] +
                      " " +
                      new Date(timePunch.time_punch).toLocaleTimeString()}
                  </li>
                  {currentUserData && currentUserData[0]?.role === "Admin" ? (
                    <>
                      <button
                        className="border-none green-submit-btn width-95-percent"
                        onClick={() => {
                          setToggleTimePunch({
                            timePunch: timePunch.time_punch,
                            timePunchID: timePunch.timesheet_id,
                          });
                          setToggleEditTimePopup(true);
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className="border-none green-submit-btn width-95-percent"
                        onClick={() => {
                          setDeleteTimePunchDate(timePunch.time_punch);
                          setDeletePunchId(timePunch.timesheet_id);
                          setToggleDelete(true);
                        }}
                      >
                        Delete
                      </button>
                    </>
                  ) : (
                    <>
                      <button className="border-none color-red">Flag</button>
                    </>
                  )}
                </div>
              ))}
          </div>
          <div>Total hours for day {totalHoursWorkedForSelectedDate.hours + "h " + totalHoursWorkedForSelectedDate.minutes + 'm' }</div>
          
          <span className="margin-bottom-10">
            <i>
              *Total hours are calculated based on first login and final logout
              of the day
            </i>
          </span>
        </div>
      </div>
    </>
  );
}
