import React, { useEffect, useState, useRef } from "react";
import Calender_popup from "./calender_popup/Calender_popup";
import { patchCompleteTasks } from "../src/fetches/fetch";
import { changePageState } from "./redux/pageNavigator";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
let api = require("./apiConfig");
api = api.api;
const jwt = Cookies.get("jwt");

function Leftside({
  setSearchTerm,
  
  setCurrentHouseholdId,
  getTasks,
  tasks,
  recentCustomers,
  gg,
}) {
  const searchTermValue = useRef<any>(null);
  const dispatch = useDispatch();
  interface recentCustomers {
    household_id: number;
    firstname: string;
    lastname: string;
  }

  useEffect(() => {
    getTasks();
  }, []);

  const uncompletedTasks = tasks ? tasks.filter((task) => task.is_completed === false ) : tasks;

  const removeHyphens = (string: string) => {
    return string.replace(/-/g, "");
  };

  const changeCurrentHouseholdId = (id) => {
    setCurrentHouseholdId(id);
    Cookies.set("currentHouseholdId", id);
  };
  const renderSearchResults = () => {
    if (searchTermValue.current && searchTermValue.current.value) {
      let noHyphens = removeHyphens(searchTermValue?.current?.value);
      setSearchTerm(noHyphens.trim());
    }
    dispatch(changePageState('leads'));
  };

  const onKeyDownEnter = (e) => {
    if (e.keyCode === 13) {
      if (searchTermValue.current && searchTermValue.current.value) {
        let noHyphens = removeHyphens(searchTermValue?.current?.value);
        setSearchTerm(noHyphens.trim());
      }
      dispatch(changePageState('leads'));
    }
  };
  const handleCompleteTask = async (task_id) => {
    const response: any = await patchCompleteTasks(task_id);
    if (response.status === 200) {
      getTasks();
    }
  };
  const handleRecentCustomerClick = (household_id: any) => { 
    changeCurrentHouseholdId(household_id)
    dispatch(changePageState('lead'))
  }

  return (
    <>
    
      <div className=" flex-column-no-ho-centered max-height side-width">
      
        <div className="light-dark-container  " id="upcoming-assignments">
          <h3 className="light-dark-container">Search customer</h3>
          <div className="flex-across regularTextInput width-95-percent ">
            <input
              type="text"
              className=" no-border outline-none   dark-background  max-width"
              id="search-driver-input"
              ref={searchTermValue}
              onKeyDown={(e) => onKeyDownEnter(e)}
            />
            <button
              type="button"
              className=" margin-left-5  no-border"
              id="left_panel_search_button "
              onClick={() => renderSearchResults()}
            >
              🔍
            </button>
          </div>
        </div>

        <div className="light-dark-container max-height margin-top-10px ">
          <h3 className="light-dark-container">Recent Customers</h3>
          <div className="  light-dark-container">
            {recentCustomers ? (
              recentCustomers.slice(0, 8).map((recentCustomer) => (
                <li
                  className="light-dark-container display-block block-display cursor-pointer hover"
                  onClick={() => {
                    handleRecentCustomerClick(recentCustomer.household_id);
                    
                  }}
                >
                  {recentCustomer?.organization &&
                  recentCustomer?.organization !== "null"
                    ? recentCustomer.organization
                    : recentCustomer.full_name}
                </li>
              ))
            ) : (
              <a href="#" className="light-dark-container">
                No drivers to display
              </a>
            )}
          </div>
        </div>

        <div
          className="light-dark-container margin-top-10px max-height  word-wrap "
          id="upcoming-assignments "
        >
          <h3 className="light-dark-container  ">Upcoming appointments</h3>
          {tasks &&
           uncompletedTasks.slice(0,5).map((task) => (
              <div className="flex-across light-dark-background">
                {task && task.is_completed === false ? (
                  <li
                    className="light-dark-container display-block block-display cursor-pointer hover border-box-ua "
                    onClick={() => {
                      Cookies.set("currentHouseholdId", task.household_id[0]);
                      setCurrentHouseholdId(task.household_id[0]);
                      dispatch(changePageState('lead'));
                    }}
                  >
                    <span className="flex-across-space-between light-dark-container">
                      {task.full_name +
                        " - " +
                        new Date(task.task_date).toLocaleDateString("en-US", {
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                          hour12: true,
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        })}
                    </span>
                  </li>
                ) : null}
                {task.is_completed === false ? (
                  <button
                    type="button"
                    className="green-submit-btn"
                    onClick={() => handleCompleteTask(task.task_id)}
                  >
                    Complete
                  </button>
                ) : null}
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default Leftside;
