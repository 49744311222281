import React, { useState, useRef, useEffect } from "react";
import Cookies from "js-cookie";
import "./reuseables.css";
import usePreventDoubleClick from "./usePreventDoubleClick";
import { useSelector, useDispatch } from "react-redux";
import { setJWT } from "./redux/initialJWT"
const { postTimePunch } = require("../src/fetches/fetch");
let api = require("./apiConfig");
api = api.api;

function Login() {
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  const token = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");
  const { isButtonDisabled, preventDoubleClick } = usePreventDoubleClick();
  const [usernameState, setUsername] = useState();
  const [passwordState, setPassword] = useState();
  const [displaySecurityToken, setDisplaySecurityToken] = useState<
    boolean | null
  >(false);
  const [tokenInput, setTokenInput ] = useState("");
  const dispatch = useDispatch();

  const displaySecurityTokenInput = () => {
    setUsername(usernameRef.current.value);
    setPassword(passwordRef.current.value);
    setDisplaySecurityToken(true);
    
  };

  const handleLogin = async (token) => {
    const username = usernameState;
    const password = passwordState;
    const tokenNumber = tokenInput;

    try {
      const response = await fetch(`${api}/api/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          username: username,
          password: password,
          token: tokenNumber,
        }),
      });
      if (response.status === 500) {
        return setErrorMessage("Wrong Username or Password!");
      } else if (response.status === 501) {
        return setErrorMessage("Token is expired or invalid!");
      }
      const data = await response.json();
      Cookies.set("userid", data.data.id);
      Cookies.set("jwt", data.token);
      postTimePunch(Cookies.get("userid"), new Date(), "login");
      sessionStorage.setItem('initialJWT', data.token);
      window.location.href = "/";
    } catch (error) {
      console.error(error);
      return setErrorMessage("Down for maintenance!");
    }
  };
  const editInputFields = () => {
    setErrorMessage("");
  };

  const checkIfUsernamePasswordPresent = () => { 
    if (usernameRef.current.value && passwordRef.current.value) {
      displaySecurityTokenInput();
    } else {
      setErrorMessage("Enter password and username");
    }
  }

  const handleChangeTokenInput = (e) =>{ 
    setTokenInput(e.target.value);
    setErrorMessage("");
  }
  const handleEnterLogin = async(e) =>{ 
    if(e.key === "Enter" && token.current.value){ 
    
      await handleLogin(e.target.value)
    }else{ 
      setErrorMessage("Enter a token please")
    }
    
  }
  
  const handleRegularLogin = async() =>{ 
    if( tokenInput){ 
    
      await handleLogin(tokenInput)
    }else{ 
      setErrorMessage("Enter a token please")
    }
    
  }

  const handleEnterButton = (e) => {
    if (e.key === "Enter") {
      checkIfUsernamePasswordPresent();
  };
 


}


  return (
    <div className="center-div-flex">
      <div className="flex-container-el ">
        <h2>Login</h2>
        {displaySecurityToken === false ? (
          <>
            <input
              type="text"
              className="regularTextInputLogin max-width margin-top-10px"
              autoComplete="off"
              placeholder="Username"
              ref={usernameRef}
              required={true}
              onKeyDown={handleEnterButton}
              onChange={editInputFields}
            />
            <input
              type="password"
              className="regularTextInputLogin max-width margin-top-10px"
              autoComplete="off"
              placeholder="Password"
              required={true}
              ref={passwordRef}
              onKeyDown={handleEnterButton}
              onChange={editInputFields}
            />
             <button
              type="button"
              className="green-submit-btnLogin margin-top-10px max-width"
              onClick={() => checkIfUsernamePasswordPresent() }
              disabled={isButtonDisabled}
            >
              Login🔐
            </button>
          </>
        ) : (
          <>
          <span className="margin-top-10px">Enter TOTP code from your authentication app</span>
            <input
              type="token"
              className="regularTextInputLogin max-width margin-top-10px"
              autoComplete="off"
              placeholder="Security Code"
              ref={token}
              value = {tokenInput}
              onKeyDown={handleEnterLogin}
              onChange={handleChangeTokenInput}
            />
            <button
              type="button"
              className="green-submit-btnLogin margin-top-10px max-width"
              onClick={() => preventDoubleClick(handleRegularLogin)}
              disabled={isButtonDisabled}
            >
              Login🔐
            </button>
            <button
              type="button"
              className="green-submit-btnLogin margin-top-10px max-width"
              onClick={() => window.location.href = '/'}
            >
              Go Back
            </button>
          </>
        )}
         
        <p data-testid="error_message" className="margin-top-10px color-red">
          {errorMessage}
        </p>
      </div>
    </div>
  );
}

export default Login;
