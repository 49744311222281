import React from 'react'

function SearchUser({userIdAndFullName, setFilteredUserList}) {

   const filterUsers = (input, userIdAndFullName) => {
    
      return Object.entries(userIdAndFullName)
        .filter(([id, fullName]) => fullName.toLowerCase().includes(input.toLowerCase()))
        .reduce((acc, [id, fullName]) => {
          acc[id] = fullName;
          return acc;
        }, {});
    };
    const setFilteredUsers = (input) => { 
      const filtered = filterUsers(input, userIdAndFullName);
      setFilteredUserList(filtered)
    }

  return (
    <div className='light-hover user_list'>
        
        <input type = 'text' className='regularTextInput max-width  '  placeholder='Search User' onChange = {(e) => setFilteredUsers(e.target.value)}/>
    </div>
  )
}

export default SearchUser