import React from "react";
import Cookies from "js-cookie";
const { getChats } = require("../fetches/fetch.js");

function User_List({
  userIdAndFullName,
  selectedUser,
  setSelectedUser,
  filteredUserList,
  handleSettingMessages,
  userNotificationData,
  deleteNotifications
}) {
  const userList = filteredUserList || userIdAndFullName;
  

  return (
    <div className="max-width user_list">
      <ul className="user_list overflow-user-list">
        {Object.keys(userList).map((key) => {
          if (key === Cookies.get("userid")) return null; // Skip current user

          return (
          
              <li
                key={key}
                className={
                  selectedUser === key
                    ? "user_list bottom-margin selectedUser-chat cursor-pointer"
                    : "user_list bottom-margin cursor-pointer fade-in"
                }
                onClick={() => {
                  setSelectedUser(key);
                  handleSettingMessages(key, 0);
                  deleteNotifications(Cookies.get("userid"), key);
                }}
              >
                {userIdAndFullName[key]}
                {userNotificationData &&
                userNotificationData.some((user) => user.from_user_id === key) && ( 
                 <div className={
                  selectedUser === key
                    ? "   inline-el notification-chat-bg color-red"
                    : "  fade-in inline-el color-red "
                } > New</div>
                )}
              </li>

             
        
          );
        })}
      </ul>
    </div>
  );
}

export default User_List;
