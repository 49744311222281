import React, { useState, useEffect, useRef } from "react";
import PayrollTable from "./PayrollTable.js";
import PayrollCommissionAndBonusTable from "./PayrollCommissionAndBonusTable.js";
import PayrollCommissionAndBonusTableTotalPay from "./PayrollCommissionAndBonusTableTotalPay.js";
import PayrollDatePicker from "./PayrollDatePicker.js";
const {
  get_payroll,
  getUserCommissions,

} = require("../fetches/fetch.js");

function Payroll({ userIdAndFullName, allUserData }) {
  const today = new Date();
  const previousMonthDate = new Date();
  previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);
  const [startDate, setStartDate] = useState(
    new Date(previousMonthDate.getFullYear(), previousMonthDate.getMonth(), 25)
  );
  const [endDate, setEndDate] = useState(
    new Date(today.getFullYear(), today.getMonth(), 25)
  );
  const [allPayrollData, setAllPayrollData] = useState();
  const [totalPayout, setTotalPayout] = useState();
  const [commissionsData, setCommissionsData] = useState();
  const [periodOneProcessedDate, setProcessedDatePeriodOne] = useState();
  const [periodTwoProcessedDate, setProcessedDatePeriodTwo] = useState();


  const setUserCommissionsData = async (startDate, endDate) => {
    const response = await getUserCommissions(startDate, endDate);
    const data = await response.json();
    setCommissionsData(data);
  };
  useEffect(() => {
    if (startDate) {
      const date = new Date();

      const previousMonth = startDate.getMonth() + 1;
      const periodOneMonth = date.setMonth(previousMonth);
      const processedDatePeriodOne = new Date(periodOneMonth);
      setProcessedDatePeriodOne(processedDatePeriodOne);
    }
  }, [startDate]);

  useEffect(() => {
    const date = new Date();
    const processedEndOfMonth = date.setMonth(endDate.getMonth() + 1, 0);
    const processedDatePeriodTwo = new Date(processedEndOfMonth);
    setProcessedDatePeriodTwo(processedDatePeriodTwo);
  }, [endDate]);



  useEffect(() => {
    setUserCommissionsData(
      startDate.toISOString().split("T")[0],
      endDate.toISOString().split("T")[0]
    );
  }, [startDate, endDate]);

  useEffect(() => {
    async function fn() {
      const response = await get_payroll(
        new Date(startDate).toISOString(),
        new Date(endDate).toISOString()
      );
      const data = await response.json();
      setAllPayrollData(data);

      const total = data.reduce((acc, userPayrollData) => {
        if (userPayrollData.on_payroll === true && allUserData) {
          const hourlyRate =
            allUserData.find(
              (user) => userPayrollData.user_id === String(user.id)
            )?.hourly_rate || 0;
          const payForRow =
            userPayrollData.total_hours * hourlyRate +
            (userPayrollData.total_minutes / 60) * hourlyRate;
          return acc + payForRow;
        }
        return acc;
      }, 0);

      setTotalPayout(total);
    }
    fn();
  }, [startDate, endDate]);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div className="full-height-overflow-y-90">
      <PayrollDatePicker
        startDate={startDate}
        setStartDate={setStartDate}
        months={months}
        previousMonthDate={previousMonthDate}
        endDate={endDate}
        setEndDate={setEndDate}
        today={today}
      />
      <div className="flex-across-space-between flex-column-no-ho-centered max-height">
        <div>
          <div className="bottom-margin-25 ">
            <PayrollCommissionAndBonusTable
              userIdAndFullName={userIdAndFullName}
              allUserData={allUserData}
              commissionsData={commissionsData}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
          <div className=" bottom-margin-25 ">
            <div className="flex-across-space-between">
              <h3>
                Timesheet & Salary
                <i>
                  {" "}
                  1st - 15th of{" "}
                  {startDate.toLocaleDateString("en-US", {
                    month: "long",
                    year: "numeric",
                  })}
                </i>
              </h3>
              <h3 className="margin-right-20px">
                Processed Date{" "}
                {+" " + periodOneProcessedDate &&
                  new Date(
                    startDate.getFullYear(),
                    startDate.getMonth() + 1,
                    0
                  ).getDate() +
                    "th Of " +
                    months[startDate.getMonth()] +
                    " " +
                    startDate.getFullYear()}{" "}
              </h3>
            </div>

            <PayrollTable
              userIdAndFullName={userIdAndFullName}
              allUserData={allUserData}
              startDate={new Date(
                startDate.getFullYear(),
                startDate.getMonth(),
                1
              )
                .toISOString()
                .slice(0, 10)}
              endDate={new Date(
                startDate.getFullYear(),
                startDate.getMonth(),
                15
              )
                .toISOString()
                .slice(0, 10)}
            />
          </div>
          <div className=" bottom-margin-25 ">
            <div className="flex-across-space-between">
              <h3>
                Timesheet & Salary
                <i>
                  {periodTwoProcessedDate &&
                    " 16th -  " +
                      new Date(
                        startDate.getFullYear(),
                        startDate.getMonth() + 1,
                        0
                      ).getDate() +
                      "th of  "}

                  {startDate.toLocaleDateString("en-US", {
                    month: "long",
                    year: "numeric",
                  })}
                </i>
              </h3>
              <h3 className="margin-right-20px ">
                Processed Date 14th Of{" "}
                {periodOneProcessedDate &&
                  periodOneProcessedDate.toLocaleDateString("en-US", {
                    month: "long",
                    year: "numeric",
                  })}{" "}
              </h3>
            </div>
            <PayrollTable
              userIdAndFullName={userIdAndFullName}
              allUserData={allUserData}
              startDate={new Date(
                startDate.getFullYear(),
                startDate.getMonth(),
                16
              )
                .toISOString()
                .slice(0, 10)}
              endDate={new Date(
                startDate.getFullYear(),
                startDate.getMonth() + 1,
                0
              )
                .toISOString()
                .slice(0, 10)}
            />
          </div>
        </div>
        <div>
        
          <PayrollCommissionAndBonusTableTotalPay
            startDate = {startDate}
            endDate = {endDate}
            payrollData={allPayrollData}
            userIdAndFullName={userIdAndFullName}
            allUserData={allUserData}
            commissionsData={commissionsData}
          />
        </div>
      </div>
    </div>
  );
}

export default Payroll;
