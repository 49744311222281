import { useState, useEffect } from "react";

export const useCalculatePay = (time_punches) => {
  const [totalHours, setTotalHours] = useState(0);
  const [totalPay, setTotalPay] = useState(0);

  useEffect(() => {
    if (!time_punches || time_punches.length === 0) {
      setTotalHours(0);
      setTotalPay(0);
      return;
    }

    const calculatedHours = time_punches.reduce(
      (sum, timePunch) => sum + timePunch.logged_in_time_minutes / 60,
      0
    );

    const calculatedPay = time_punches.reduce(
      (sum, timePunch) =>
        sum + (timePunch.logged_in_time_minutes / 60) * timePunch.hourly_rate,
      0
    );

    setTotalHours(calculatedHours);
    setTotalPay(calculatedPay);
  }, [time_punches]);


  return { totalHours, totalPay };
};
