import React, { useState } from "react";
import TimeTablePopup from "../time_table_popup/TimeTablePopup";
import EditTimeTablePopup from "../time_table_popup/EditTimeTablePopup";

const { handleGettingDate } = require("../fetches/fetch");

export function TimeTable({
  timePunches,
  currentUserData,
  user,
  returnTimePunches,
}) {
  const [dayPunchLog, setDayPunchLog] = useState();
  const [togglePopup, setTogglePopup] = useState(false);
  const [toggleEditTimePopup, setToggleEditTimePopup] = useState(false);
  const [editTimePunch, setToggleTimePunch] = useState();
  const [totalHoursWorkedForSelectedDate, setTotalHoursWorkedForSelectedDate] =
    useState();
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];


  const handleTimeTableRowClick = async (
    date,
    user_id,
    calculate_logged_hours
  ) => {
    const response = await handleGettingDate(date, user_id);
    if (response.status === 200) {
      const data = await response.json();
      if (calculate_logged_hours === true) {
        return setDayPunchLog(data);
      } else {
        setTogglePopup(true);
        return setDayPunchLog(data);
      }
    }
    return alert("Erroring geting pucnhes for that day");
  };


  return (
    <div className="overflow-50-percent">
      <table className="max-width">
        <thead>
          <th>Date</th>
          <th>First Clock In</th>
          <th>Final Clock Out</th>
          <th>Time Spent Logged In</th>
         
          <th>Pay</th>
        </thead>
        <tbody id="search-results_table_body">
          {currentUserData &&
            timePunches &&
            timePunches.map((timePunch) => (
              <>
                <tr
                  onClick={() => {
                    handleTimeTableRowClick(timePunch.first_punch, user);
                    setTotalHoursWorkedForSelectedDate({
                      hours: timePunch.hours,
                      minutes: timePunch.minutes,
                    });
                  }}
                >
                  <td>
                    {" "}
                    {daysOfWeek[new Date(timePunch.first_punch).getDay()]}
                    <br />
                    {new Date(timePunch.first_punch).toLocaleDateString()}
                  </td>
                  <td>
                    {new Date(timePunch.first_punch).toLocaleTimeString()}
                  </td>
                  <td>{new Date(timePunch.last_punch).toLocaleTimeString()}</td>
                  <td>
                    {Math.floor(timePunch.logged_in_time_minutes / 60) +
                      "h " +
                      (timePunch.logged_in_time_minutes % 60) +
                      "m"}{" "}
                  </td>
                  <td>
                    {(() => {
                      const totalPay =
                        (timePunch.logged_in_time_minutes / 60) *
                        timePunch.hourly_rate;

                      return `$${Math.floor(totalPay)}`; // Show the result as a formatted currency
                    })()}
                  </td>
                </tr>
              </>
            ))}
        </tbody>
      </table>
      {togglePopup && (
        <TimeTablePopup
          setToggleTimePunch={setToggleTimePunch}
          returnTimePunches={returnTimePunches}
          setToggleEditTimePopup={setToggleEditTimePopup}
          handleTimeTableRowClick={handleTimeTableRowClick}
          user={user}
          currentUserData={currentUserData}
          timePunches={dayPunchLog}
          daysOfWeek={daysOfWeek}
          setTogglePopup={setTogglePopup}
          totalHoursWorkedForSelectedDate={totalHoursWorkedForSelectedDate}
        />
      )}
      {toggleEditTimePopup && (
        <EditTimeTablePopup
          handleTimeTableRowClick={handleTimeTableRowClick}
          user={user}
          editTimePunch={editTimePunch}
          returnTimePunches={returnTimePunches}
          daysOfWeek={daysOfWeek}
          timePunches={dayPunchLog}
          setTogglePopup={setToggleEditTimePopup}
        />
      )}
    </div>
  );
}
