import React, { useState, useEffect } from "react";
import Draggleable from "react-draggable";
import User_List from "./User_List";
import SearchUser from "./SearchUser";
import Chat_Section from "./Chat_Section";
import Cookies from "js-cookie";
const { sendChat, getChats } = require("../fetches/fetch");

function Chat_Container({
  selectedUser,
  setSelectedUser,
  userIdAndFullName,
  socket,
  userNotificationData, 
  deleteNotifications, 
  setShowChatBox
}) {
  const [filteredUserList, setFilteredUserList] = useState();
  const [currentMessages, setCurrentMessages] = useState();

  const handleGettingMessages = async (receiver, page) => {
    const response = await getChats(Cookies.get("userid"), receiver, page);
    const data = await response.json();
   
    setCurrentMessages(data);
  };

  useEffect(() => {
    handleGettingMessages(selectedUser, 0);
  }, [selectedUser]);

  
  useEffect(() => {
    /*HandleGetting Messages triggers a scroll due to an useEffect dependancy array that listens for newMessages */ 
    if(userNotificationData && userNotificationData.length > 0 && userNotificationData.some(user => user.from_user_id === selectedUser) ){ 
      handleGettingMessages(selectedUser, 0);
      deleteNotifications(Cookies.get('userid'), selectedUser);
    }
  }, [userNotificationData]);




  useEffect(() => {
    const handleNewMessage = async () => {
      await handleGettingMessages(selectedUser, 0);
    };

    socket.on("newMessage", handleNewMessage);

    return () => {
      socket.off("newMessage", handleNewMessage); // Properly cleans up
    };
  }, [socket, selectedUser]); // Dependencies are still `socket` and `selectedUser` 

  return (
    <Draggleable cancel=".chat-bubble, input, li">
      <div className="position-absolute z-index-20 black-background width-800px">
        <div className=" flex-across max-width">
          <div className=" padding-10px width-60-percent ">
            <Chat_Section
              currentMessages={currentMessages}
              userIdAndFullName={userIdAndFullName}
              selectedUser={selectedUser}
              setCurrentMessages={setCurrentMessages}
              handleGettingMessages={handleGettingMessages}
            />
          </div>
          <div className="  flex-one user_list ">
            <div className=" padding-20px user_list margin-bottom border-bottom ">
              <SearchUser
                setFilteredUserList={setFilteredUserList}
                userIdAndFullName={userIdAndFullName}
              />
            </div>
            <div className=" flex-one  ">
              <div className="padding-20px user_list">
                <User_List
                  userNotificationData= {userNotificationData}
                  filteredUserList={filteredUserList}
                  userIdAndFullName={userIdAndFullName}
                  selectedUser={selectedUser}
                  setSelectedUser={setSelectedUser}
                  setCurrentMessages={setCurrentMessages}
                  handleSettingMessages={handleGettingMessages}
                  deleteNotifications={deleteNotifications}
                />
              </div>
              <div className=" padding-20px  user_list cursor-pointer" onClick ={ () => setShowChatBox(false)}>
                Close{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Draggleable>
  );
}

export default Chat_Container;
