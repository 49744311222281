import React, { useRef } from "react";
import Cookies from "js-cookie";
const { sendChat, getChats } = require("../fetches/fetch");


function Chat_Input({ selectedUser,  handleGettingMessages }) {
  const input = useRef();

  
  const handleSendChat = async (to, message) => {
    const response = await sendChat(to, Cookies.get("userid"), message);
    if(response.status == 200){ 
      return await handleGettingMessages(to, 0);
    }
    return 
  };


  return (
    <div className="flex-across margin-top-10px">
      <input
        type="text"
        className="regularTextInput width-75-percent "
        ref={input}
        onKeyDown= { (e) => { if(e.key === 'Enter') {handleSendChat(selectedUser, input.current.value); input.current.value = ""}}}
      />
      <button
        className=" margin-left-5 green-submit-btn flex-one"
        onClick={() => {handleSendChat(selectedUser, input.current.value); input.current.value = ''}}
        
      >
        Send
      </button>
    </div>
  );
}

export default Chat_Input;
