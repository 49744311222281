import React from "react";
import { useCalculatePayPeriodTotal } from "./useCalculatePayPeriodTotal";
import {useGetUserSalary} from './useGetUserSalary';
function PayrollTable({ userIdAndFullName, allUserData, startDate, endDate }) {
  const { usersTotalMinutes } = useCalculatePayPeriodTotal(
    allUserData,
    startDate,
    endDate
  );
  const userSalaries = useGetUserSalary(startDate, endDate);
  
  return (
    <table>
      <thead>
        <th>Employee</th>
        <th>Total Hours And Minutes</th>
        <th>Salary</th>
        <th>Hourly</th>
        <th>Total Pay</th>
      </thead>
      <tbody>
        {allUserData &&
          allUserData.map((userPayrollData) => (
          
              <tr className="border-bottom">
                <td>{userIdAndFullName[userPayrollData.id]}</td>
                <td>
                  {Math.floor(usersTotalMinutes[userPayrollData.id] / 60)}hrs{" "}
                  {Math.floor(usersTotalMinutes[userPayrollData.id] % 60)}m
                </td>
                <td>${userSalaries && userSalaries.find(user => user.id == userPayrollData.id).salary}</td>
                <td>
                  $
                  {allUserData &&
                    allUserData.find((user) => user.id === userPayrollData.id)
                      ?.hourly_rate}
                </td>
                <td>
                  {" "}
                  $
                  { userPayrollData.on_payroll ? ((usersTotalMinutes[userPayrollData.id] / 60) *
                    allUserData.filter(
                      (user) => userPayrollData.id == String(user.id)
                    )[0]?.hourly_rate).toFixed(2) : userSalaries  && userSalaries.find(user => user.id == userPayrollData.id).salary}
                </td>
              </tr>
            
          ))}
      </tbody>
    </table>
  );
}

export default PayrollTable;
