import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import "./navbar.css";
import Add_story from "./leads_page_popups/Add_story";
import { useSelector, useDispatch } from "react-redux";
import { set_true, set_false } from "./redux/firstSlice";
import NotificationsIcon from "@mui/icons-material/Notifications";
import usePreventDoubleClick from "./usePreventDoubleClick";
import Chat_Container from "./chat_components/Chat_Container";
import { current } from "@reduxjs/toolkit";
import newChatSound from "./sounds/newChatSound.wav";

let { api } = require("./apiConfig");
const { getAnnouncement, postTimePunch } = require("./fetches/fetch");

function Navbar({
  setSwitchNumber,
  allUserData,
  currentUserData,
  userIdAndFullName,
  setCurrentPopup,
  currentPopup,
  socket,
  setSocket,
  toggleNotificationPanel,
  userNotifications,
  notificationPanel,
  breakStatus,
  setBreakStatus,
}) {
  const [showChatBox, setShowChatBox] = useState(false);
  const [notificationData, setNotificationData] = useState();
  const [userNotificationData, setUserNotificationData] = useState();
  const [announcement, setAnnouncement] = useState();
  const { isButtonDisabled, preventDoubleClick } = usePreventDoubleClick();
  const [selectedUser, setSelectedUser] = useState();
  const dispatch = useDispatch();
  const initialJWT = useSelector((currentJWT) => currentJWT.token);
  const sound = new Audio(
    "Agency%20Management%20System/react-client/agentz-client/src/sounds/newChatSound.wav"
  );
  function playSound(url) {
    const audio = new Audio(url);
    audio.play();
  }
  /* Bad code please refactor */
  const popups = [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    <Add_story setCurrentPopup={setCurrentPopup} />,
  ];

  const navigate = useNavigate();

  let userid = Cookies.get("userid");
  const handleLogout = async () => {
    const response = await postTimePunch(
      Cookies.get("userid"),
      new Date(),
      "logout"
    );
    if (response.status === 200) {
      // Delete the JWT cookie
      Cookies.remove("jwt");
      Cookies.remove("userid");
      if (socket && socket.connected) {
        socket.disconnect();
      }

      navigate("/login");
    }
  };

  const handleTakeBreak = async () => {
    let response;
    if (breakStatus === "break_on") {
      response = await postTimePunch(
        Cookies.get("userid"),
        new Date(),
        "break_off"
      );
      setBreakStatus("break_off");
    } else {
      response = await postTimePunch(
        Cookies.get("userid"),
        new Date(),
        "break_on"
      );
      setBreakStatus("break_on");
    }
    if (response.status === 500) {
      return alert("error taking break!");
    }
  };

  const toggleTeamsPopup = () => {
    document.getElementById("teams-popup").hidden =
      !document.getElementById("teams-popup").hidden;
  };

  const fetchAllUserNotifications = async (to_user_id, from_user_id) => {
    const response = await fetch(
      `${api}/api/notifications?toUserId='${to_user_id}'&fromUserId='${from_user_id}'`
    );
    const data = await response.json();
    setNotificationData(data);
  };

  const deleteNotifications = async (to_user_id, from_user_id) => {
    const response = await fetch(
      `${api}/api/notifications?toUserId='${to_user_id}'&fromUserId='${from_user_id}'`,
      {
        method: "DELETE",
      }
    );

    if (response.ok) {
      document.title = "Agentz";
      fetchAllUserNotifications(to_user_id, from_user_id);
    }
  };

  const getNotiifcations = async () => {
    
    const response = await fetch(
      `${api}/api/notification/user?toUserId=${userid}`,
      {
        headers: {
          Authorization: sessionStorage.getItem("initialJWT")
            ? sessionStorage.getItem("initialJWT")
            : Cookies.get("jwt"),
        },
      }
    );

   
    
    

    
    if (response.status !== 500) {
      const data = await response.json();
      if (data.length !== 0) {
    
        document.getElementById("teams-popup").hidden = false;
        document.title = "Agetnz - New Message!";
        playSound(newChatSound);
      }
  
      setUserNotificationData(data);
    } else {
     
      window.location.href = "/login";
    }
  };

  useEffect(() => {
    const intervalId = setInterval(async() => {
      await getNotiifcations();
    }, 5000);
    return () => clearInterval(intervalId);
  }, [api]);

  useEffect(() => {
    const fetchAnnouncement = async () => {
      try {
        const response = await getAnnouncement();
        const data = await response.json();
        setAnnouncement(data);
      } catch (error) {
        console.error("Error fetching announcement:", error);
      }
    };

    fetchAnnouncement();

    const intervalId = setInterval(fetchAnnouncement, 300000);
    return () => clearInterval(intervalId);
  }, [api]);

  const openChat = (user_id) => {
    deleteNotifications(Cookies.get("userid"), user_id);
    setSelectedUser(user_id);
    setShowChatBox(true);
  };

  return (
    <>
      {showChatBox ? (
        <Chat_Container
          //currentSelectedUserId={currentSelectedUserId}
          setShowChatBox={setShowChatBox}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          socket={socket}
          userIdAndFullName={userIdAndFullName}
          userNotificationData={userNotificationData}
          deleteNotifications={deleteNotifications}
        />
      ) : null}
      <nav className="navbar flex-across-space-between ">
        <button
          className="max-height"
          type="button"
          id="nav-popup-menu-btn"
          onClick={() => dispatch(set_true())}
        >
          ☰
        </button>
        <div className=" overflow-x  light-dark-background flex-center-hor max-width ">
          <label className="light-dark-background ticker max-width ">
            {userNotificationData && userNotificationData.length > 0
              ? "New message"
              : announcement && announcement.announcement_desc}
          </label>
        </div>
        <div className="light-dark-background flex-across width-5-percent space-between align-items-center">
          <NotificationsIcon
            fontSize="small"
            htmlColor="black"
            className={`cursor-pointer light-dark-background ${
              userNotifications &&
              userNotifications.length &&
              notificationPanel === false > 0
                ? "shake"
                : null
            } `}
            onClick={() => {
              toggleNotificationPanel((prevState) => {
                return !prevState;
              });
            }}
          />

          <h4
            className="light-dark-background cursor-pointer"
            onClick={() => toggleTeamsPopup()}
          >
            Teams
          </h4>

          <h4
            className=" light-dark-background cursor-pointer"
            onClick={() => {
              preventDoubleClick(handleLogout);
            }}
            disabled={isButtonDisabled}
          >
            Logout
          </h4>
        </div>
      </nav>

      <div
        className="position-absolute margin-top-50px position-right  z-index "
        id="teams-popup"
        data-testid="teams-popup-display"
      >
        <ul className="light-dark-background ">
          {allUserData
            ? allUserData.map((userData, index) => (
                <div className="light-dark-background margin-left-5 margrin-bottom-5px hover-cursor-pointer margin-top-5px display-flex">
                  <li
                    className="light-dark-background padding-left-2px inline-el "
                    data-testid="chat-btn"
                    onClick={() => openChat(userData.id)}
                  >
                    {userData.id === userid ? null : userData.full_name}
                    {userNotificationData &&
                      userNotificationData.some(
                        (notification) =>
                          notification.from_user_id === userData.id
                      ) && (
                        <span className="notification-count color-red light-dark-background">
                          New
                        </span>
                      )}
                  </li>
                </div>
              ))
            : null}
        </ul>
      </div>

      {currentPopup && popups[currentPopup]}
    </>
  );
}

export default Navbar;
