export function ManagementNavigation({
  currentUserData,
  currentManagementPage,
  setCurrentManagementPage,
}) {
  return (
    <>
      <div className="light-dark-background flex margin-bottom-10">
        <div
          className={`light-dark-background padding-10px cursor-pointer ${
            currentManagementPage === 0 ? "management-nav" : null
          } `}
          onClick={() => setCurrentManagementPage(0)}
        >
          Timsheet
        </div>

        <div
          className={`light-dark-background padding-10px cursor-pointer ${
            currentManagementPage === 2 ? "management-nav" : null
          } `}
          onClick={() => setCurrentManagementPage(2)}
        >
          Commissions
        </div>
        <div
          className={`light-dark-background padding-10px cursor-pointer ${
            currentManagementPage === 3 ? "management-nav" : null
          } `}
          onClick={() => setCurrentManagementPage(3)}
        >
          Bonuses
        </div>
        {currentUserData && currentUserData[0].role === "Admin" ? (
          <div
            className={`light-dark-background padding-10px cursor-pointer ${
              currentManagementPage === 1 ? "management-nav" : null
            } `}
            onClick={() => setCurrentManagementPage(1)}
          >
            Payroll
          </div>
        ) : null}
      </div>
    </>
  );
}
