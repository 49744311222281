import React, { useRef } from "react";
import Cookies from "js-cookie";
import usePreventDoubleClick from "../usePreventDoubleClick";

function Generic_policy_popup({
  driverData,
  setCurrentPopup,
  insurance_companies,
  POST_POLICY,
  fetchData,
  policyType,
}) {
  const POLICY_NUMBER = useRef(null);
  const INSURANCE_COMPANY = useRef(null);
  const EFFECTIVE_DATE = useRef(null);
  const POLICY_STATUS = useRef(null);
  const POLICY_ADDRS = useRef(null);
  const TOTAL_PREM = useRef(null);
  const primary_driver = useRef(null);
  const comm_prem = useRef(null);
  const EXP_DATE = useRef();
  const sales_type = useRef();
  const { isButtonDisabled, preventDoubleClick } = usePreventDoubleClick();

  const CREATE_PUP_POLICY_OBJ = () => {
    if (
      EXP_DATE.current.value &&
      sales_type.current.value &&
      POLICY_NUMBER.current.value &&
      INSURANCE_COMPANY.current.value &&
      EFFECTIVE_DATE.current.value &&
      POLICY_STATUS.current.value &&
      POLICY_ADDRS.current.value &&
      TOTAL_PREM.current.value
    ) {
      let OBJ = {
        user_id: Cookies.get("userid"),
        policy_type: policyType,
        policy_number: POLICY_NUMBER.current.value,
        company: INSURANCE_COMPANY.current.value,
        effective_date: EFFECTIVE_DATE.current.value,
        status: POLICY_STATUS.current.value,
        address_line_2: POLICY_ADDRS.current.value,
        monthly_prem: TOTAL_PREM.current.value / 12,
        total_prem: TOTAL_PREM.current.value,
        primary_driver: primary_driver.current.value,
        comm_prem: comm_prem.current.value ? comm_prem.current.value : null,
        expiration_date: EXP_DATE.current.value,
        sales_type: sales_type.current.value,
        items: 1,
      };
      return OBJ;
    } else {
      alert("Missing input value!");
    }
  };

  const CREATE_NEW_POLICY = async () => {
    const POLICY_OBJ = CREATE_PUP_POLICY_OBJ();
    if (POLICY_OBJ) {
      const response = await POST_POLICY(POLICY_OBJ);
      if (response.status === 500) {
        alert("Policy Number invalid or already exists!");
      } else {
        fetchData();
        setCurrentPopup(null);
      }
    }
  };

  return (
    <div className="background-color-full-dark border-radius-10px popup">
      <div className="black-background  text-align-right border-radius-top-10px ">
        <button
          type="button"
          className="popup-exit-button"
          onClick={() => setCurrentPopup(null)}
        >
          ❌
        </button>
      </div>
      <div className="flex-container-el padding-20px">
        <h3 className="text-align-left margin-bottom-10">
          {policyType} Policies
        </h3>
        <label className="background-color-full-dark text-align-left margin-top-5px ">
          Policy Number*
        </label>
        <input
          type="text"
          className="background-color-full-dark regularTextInput margin-top-5px  max-width "
          autocomplete="off"
          ref={POLICY_NUMBER}
        />
        <label className="background-color-full-dark text-align-left margin-top-5px ">
          {" "}
          Insurance Companies
        </label>
        <select
          className="background-color-full-dark regularTextInput margin-top-5px max-width"
          autocomplete="off"
          ref={INSURANCE_COMPANY}
        >
          {insurance_companies &&
            insurance_companies.map((company) => <option>{company}</option>)}
        </select>
        <label className="background-color-full-dark text-align-left margin-top-5px  margin-top-5px ">
          Effective Date*
        </label>
        <input
          type="date"
          className="background-color-full-dark regularTextInput margin-top-5px max-width "
          autocomplete="off"
          ref={EFFECTIVE_DATE}
        />
        <label className="background-color-full-dark text-align-left margin-top-5px margin-top-5px ">
          Policy Status*
        </label>
        <select
          className="background-color-full-dark regularTextInput margin-top-5px  max-width "
          autocomplete="off"
          ref={POLICY_STATUS}
        >
          <option value="Active">Active</option>
          <option value="Active">Cancelled</option>
          <option value="Active">Pending Cancel</option>
          <option value="Active">Terminated</option>
        </select>
        <label className="background-color-full-dark text-align-left margin-top-5px margin-top-5px">
          Policy Address*
        </label>
        <input
          type="text"
          className="background-color-full-dark regularTextInput margin-top-5px max-width"
          id="address_line_2"
          autocomplete="off"
          ref={POLICY_ADDRS}
        />
        <label
          for="lastname"
          className="background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx "
        >
          Total Premium*
        </label>
        <input
          type="number"
          className="background-color-full-dark regularTextInput margin-top-5px request-type input max-width"
          placeholder="$0.00"
          autocomplete="off"
          ref={TOTAL_PREM}
        />
        <label
          for="lastname"
          className="background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx "
        >
          Primary Driver*
        </label>
        <select
          className="background-color-full-dark regularTextInput margin-top-5px request-type input max-width"
          autocomplete="off"
          ref={primary_driver}
        >
          {driverData &&
            driverData.map((driver) => (
              <option value={driver.drivers_id}>{driver.full_name}</option>
            ))}
        </select>
        <label
          for="lastname"
          className="background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx "
        >
          Expiration Date*
        </label>
        <input
          type="date"
          className="background-color-full-dark regularTextInput margin-top-5px request-type input max-width"
          autocomplete="off"
          ref={EXP_DATE}
        />
        <label
          for="lastname"
          className="background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx "
        >
          Sales Type*
        </label>
        <select
          className="background-color-full-dark regularTextInput margin-top-5px request-type input max-width"
          ref={sales_type}
        >
          <option value="New Business">New Business</option>
          <option value="Rewrite">Rewrite</option>
          <option value="Cancel Rewrite">Cancel Rewrite</option>
          <option value="Cross Sale">Cross Sale</option>
          <option value = 'AOR Change'>AOR Change</option>
        </select>
        <label className="background-color-full-dark text-align-left margin-top-5px margin-top-5px">
          Commissionable Premium
        </label>
        <input
          type="text"
          className="background-color-full-dark regularTextInput margin-top-5px max-width"
          id="address_line_2"
          autocomplete="off"
          ref={comm_prem}
        />
        <label
          for="lastname"
          className="background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx "
        >
          Date Bound
        </label>
        <span>{new Date().toLocaleDateString()}</span>
        <button
          type="button"
          class="button-default green-submit-btn margin-top-10px max-width"
          onClick={() => preventDoubleClick(CREATE_NEW_POLICY)}
          disabled={isButtonDisabled}
        >
          Add new policy
        </button>
      </div>
    </div>
  );
}

export default Generic_policy_popup;
