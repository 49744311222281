import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import LeadsPage from './LeadsPage';
import { changeTheme } from './sidebar_popups/Settings.tsx';
const { postTimePunch } = require('../src/fetches/fetch.js')
let api = require('./apiConfig');
api = api.api;


const ProtectedRoute = () => {
  const navigate = useNavigate();
  const [tokenValid, setTokenValid] = useState(false);




  useEffect(() => {
    changeTheme(Cookies.get('theme'))
    const verifyToken = async () => {
      const token = Cookies.get('jwt');
      if (!token) {
        navigate("/login");
        return;
      }

      try {
        const response = await fetch(`${api}/api/verify`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization' : token,
          }
        });

        if (response.status === 200) {
          setTokenValid(true);
        } else {
          navigate("/");
        }
      } catch (error) {
        navigate("/login");
      }
    };

    verifyToken();
  }, [navigate]);

   React.useEffect(() => {
    window.addEventListener("beforeunload", async() => { 
      console.log('test')
    });
    }, []);

  return (
    <>
      {tokenValid ? <LeadsPage /> :<> <div className = "flex-center-column"><img   src = "https://i.gifer.com/ZKZg.gif"/></div></> }

     
    </>
  );
};

export default ProtectedRoute;
