

function calculateBonusAmount(total_prem) {
  /*J5 IS THE TOTAL PREM */
  if (total_prem >= 10000 && total_prem < 14999) {
    return 200;
  } else if (total_prem >= 15000 && total_prem < 19999) {
    return 250;
  } else if (total_prem >= 20000 && total_prem < 29999) {
    return 500;
  } else if (total_prem >= 30000 && total_prem < 39999) {
    return 750;
  } else if (total_prem >= 40000 && total_prem < 59999) {
    return 1000;
  } else if (total_prem >= 60000 && total_prem < 79999) {
    return 1250;
  } else if (total_prem >= 80000 && total_prem < 99999) {
    return 1500;
  } else if (total_prem >= 100000 && total_prem <= 100000) {
    return 2000;
  } else {
    return 0;
  }
}

function calculateBonusAfterBundlePercentageCalculation(
  bundled_rate_percentage,
  bonus_amount,
  items
) {
  /*    
     bundled_rate_percentage is bundle rate percentage 
     bonus_amount will be the bonus amount 
     If number of Items are less than 20 then return 0 
    */
  if (items < 20) {
    return 0;
  }
  if (bundled_rate_percentage >= 0.3 && bundled_rate_percentage <= 0.39) {
    return 0.6 * bonus_amount;
  } else if (
    bundled_rate_percentage >= 0.4 &&
    bundled_rate_percentage <= 0.49
  ) {
    return 0.8 * bonus_amount;
  } else if (bundled_rate_percentage >= 0.5 && bundled_rate_percentage <= 1) {
    return bonus_amount;
  } else {
    return 0;
  }
}

export const useCalculateBonus = (bonusData) => {
    if (!bonusData || bonusData.length === 0) {
      return [];
    }
  
    return bonusData.map((object) => {
      const bundledRate = ((object.numOfMultiline / object.numOfItems) * 100).toFixed(2);
      const bonusAmount = calculateBonusAmount(object.total_prem);
      const finalBonusAmount = calculateBonusAfterBundlePercentageCalculation(
        bundledRate,
        bonusAmount,
        object.items
      );
  
      return {
        ...object,
        bundledRate,
        bonusAmount,
        finalBonusAmount,
      };
    });
  };
  
