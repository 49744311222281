import React, {useRef} from 'react'



function PayrollDatePicker({startDate, setStartDate, months, previousMonthDate, endDate, setEndDate, today}) {
    const toYear = useRef();
    const fromYear = useRef();
    const fromDateSelectEl = useRef();
    const toDateSelectEl = useRef();
  return (
    <div className="flex align-items-center light-dark-background margin-bottom-10  ">
        <p className="light-dark-background padding-5px">From: 25th Of </p>
        <select
          value={new Date(startDate).getMonth()}
          className="regularTextInput"
          ref={fromDateSelectEl}
          onChange={(e) => {
            const date = new Date();
            date.setMonth(e.target.value);
            date.setDate(25);
            date.setFullYear(fromYear.current.value);
            setStartDate(date);
          }}
        >
          {months &&
            months.map((month, index) => (
              <option value={index}>{month}</option>
            ))}
        </select>
        <input
          type="number"
          className="regularTextInput margin-left-5 wdith-70px"
          defaultValue={previousMonthDate.getFullYear()}
          placeholder="yyyy"
          min="1000"
          max="9999"
          ref={fromYear}
          maxlength={4}
          onChange={(e) => {
            /*Only updates when a valid year is present in the field */
            if (e.target.value > 1000 && e.target.value < 9000) {
              const date = new Date();
              date.setMonth(fromDateSelectEl.current.value);
              date.setDate(25);
              date.setFullYear(fromYear.current.value);
              setStartDate(date);
            }
          }}
        />

        <p className="light-dark-background margin-left-5">To: 25th Of</p>

        <select
          className="regularTextInput margin-left-5 "
          ref={toDateSelectEl}
          value={new Date(endDate).getMonth()}
          onChange={(e) => {
            const date = new Date();
            date.setMonth(e.target.value);
            date.setDate(25);
            date.setFullYear(toYear.current.value);
            setEndDate(date);
          }}
        >
          {months &&
            months.map((month, index) => (
              <option value={index}>{month}</option>
            ))}
        </select>
        <input
          type="number"
          className="regularTextInput margin-left-5 wdith-70px"
          defaultValue={today.getFullYear()}
          min="1000"
          max="9999"
          ref={toYear}
          maxlength={4}
          onChange={(e) => {
            /*Only updates when a valid year is present in the field */
            if (e.target.value > 1000 && e.target.value < 9000) {
              const date = new Date();
              date.setMonth(toDateSelectEl.current.value);
              date.setDate(25);
              date.setFullYear(toYear.current.value);
              setEndDate(date);
            }
          }}
        />
      </div>
  )
}

export default PayrollDatePicker