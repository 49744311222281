import { createSlice } from '@reduxjs/toolkit'

 const currentJWT = createSlice({ 
    name: 'currentJWT', 
    initialState: { 
        token:null
    }, 
    reducers: { 
        setJWT:(state, action) => { 
            
            state.token = action.payload;
        }
    }
})

export const {setJWT} = currentJWT.actions; 
export default currentJWT.reducer