import React, { useState, useEffect } from "react";
import { useCalculatePayPeriodTotal } from "./useCalculatePayPeriodTotal";
import { useCalculateBonus } from "./useCalculateBonus";
import {useGetUserSalary} from './useGetUserSalary';
const { getBonusData } = require("../fetches/fetch");

function PayrollCommissionAndBonusTableTotalPay({
  payrollData,
  userIdAndFullName,
  allUserData,
  commissionsData,
  startDate,
  endDate,
}) {
  const userSalaries = useGetUserSalary(startDate, endDate);
  console.log(userSalaries);
  const [totalAmountHeader, setTotalAmountHeader] = useState(0);
  const { usersTotalMinutes } = useCalculatePayPeriodTotal(
    allUserData,
    startDate.toISOString().slice(0, 10),
    endDate.toISOString().slice(0, 10)
  );
  const [bonusData, setBonusData] = useState();
  const { calculatedBonusData } = useCalculateBonus(bonusData);
  const fetchBonusData = async (startDate, endDate) => {
    const response = await getBonusData(
      null,
      startDate.toISOString().split("T")[0],
      endDate.toISOString().split("T")[0]
    );
    if (response.status === 500) {
      return alert("Error fetching bonus data");
    }
    const data = await response.json();

    setBonusData(data);
  };

  useEffect(() => {
    fetchBonusData(startDate, endDate);
  }, [startDate, endDate]);

  useEffect(() => {
    if(payrollData){
      const total = payrollData.reduce((acc, userPayrollData) => {
        // Only calculate for users on payroll
        if (!userPayrollData.on_payroll) return acc;
  
        const userId = userPayrollData.user_id;
  
        // Get hourly rate for the user
        const userData = allUserData.find(
          (user) => String(user.id) === userId
        );
        const hourlyRate = userData?.hourly_rate || 0;
  
        // Calculate total earnings based on minutes worked
        const totalEarnings = (usersTotalMinutes[userId] / 60) * hourlyRate;
  
        // Get commission details
        const userCommission = commissionsData.find(
          (user) =>
            user.id === userId &&
            userPayrollData.receive_commission === true
        );
        const commissionAmount = userCommission?.commission_amount || 0;
        const totalMvrFee = userCommission?.total_mvr_fee || 0;
        const totalClueFee = userCommission?.total_clue_fee || 0;
        const netCommission = commissionAmount - totalMvrFee - totalClueFee;
  
        // Get bonus if applicable
        const bonus =
          calculatedBonusData?.find(
            (user) =>
              user.user_created_id === userId &&
              userPayrollData.receive_bonus === true
          )?.bonus || 0;
  
        // Compute total payout for the user
        const totalPayout =
          Number(totalEarnings) + Number(netCommission) + Number(bonus);
  
        return acc + totalPayout;
      }, 0);
 
      // Update state with two decimal places
      setTotalAmountHeader(total.toFixed(2));
    }
    
  }, [
    payrollData,
    usersTotalMinutes,
    calculatedBonusData,
    commissionsData,
    allUserData
  ]);
  

  return (
    <>
      <div className=" margin-bottom-10 ">
        <div class>
          <h3>Totals</h3>
          <h3>${totalAmountHeader && totalAmountHeader}</h3>
        </div>

        <i>
          <b>Start: </b>
          {"25th " +
            new Date(startDate).toLocaleDateString("default", {
              month: "long",
            }) +
            ", " +
            new Date(startDate).getFullYear()}
          {"    "}
          <b>End: </b>
          {"To: 25th " +
            new Date(endDate).toLocaleDateString("default", {
              month: "long",
            }) +
            ", " +
            new Date(endDate).getFullYear()}
        </i>
      </div>
      <table>
        <thead>
          <th>Employee</th>
          <th>Total Hours And Minutes</th>
          <th>Total Pay</th>
          <th>Comissions </th>
          <th>Bonuses</th>
          <th>Total</th>
          <th></th>
        </thead>
        <tbody>
          {payrollData &&
            payrollData.map((userPayrollData, index) => {
              if (!userPayrollData.on_payroll) return null;

              const userId = userPayrollData.user_id;
              const userFullName = userIdAndFullName[userId];

              // Find user data for hourly rate
              const userData = allUserData.find(
                (user) => String(user.id) === userId
              );
              const hourlyRate = userData?.hourly_rate || 0;

              // Calculate total earnings
              const totalEarnings = (
                (usersTotalMinutes[userId] / 60) *
                hourlyRate
              ).toFixed(2);

              // Find user commission
              const userCommission = commissionsData?.find(
                (user) =>
                  user.id === userId &&
                  userPayrollData.receive_commission === true
              );
              const commissionAmount = userCommission?.commission_amount || 0;
              const totalMvrFee = userCommission?.total_mvr_fee || 0;
              const totalClueFee = userCommission?.total_clue_fee || 0;
              const netCommission = (
                commissionAmount -
                totalMvrFee -
                totalClueFee
              ).toFixed(2);

              // Find bonus
              const bonus =
                calculatedBonusData?.find(
                  (user) =>
                    user.user_created_id === userId &&
                    userPayrollData.receive_bonus === true
                )?.bonus || 0;
              const totalPayout = (
                Number(totalEarnings) +
                Number(netCommission) +
                Number(bonus)
              ).toFixed(2);
         
              return (
                <tr key={index} className="border-bottom">
                  <td>{userFullName}</td>
                  <td>
                    {Math.floor(
                      usersTotalMinutes[userPayrollData.user_id] / 60
                    )}
                    hrs{" "}
                    {Math.floor(
                      usersTotalMinutes[userPayrollData.user_id] % 60
                    )}
                    m
                  </td>
                  <td>${totalEarnings}</td>
                  <td>${netCommission}</td>
                  <td>${bonus}</td>
                  <td>${totalPayout}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
}

export default PayrollCommissionAndBonusTableTotalPay;
